import { Field, FieldType, IColumnMetadata } from '@wf-mfe-maestro/api';

import { SequentialMap } from '../../SequentialMap';
import { ReadonlyField, recordTypeSubject$ } from '../../../state';

export const mapColumnMetaData = (
  fields: readonly Field[],
  columnMetadata: IColumnMetadata[] = [],
  fieldMap: Record<string, ReadonlyField>
) => {
  const primaryFieldId = recordTypeSubject$.getValue().primaryFieldId;

  const fieldsMap = new SequentialMap<string, IColumnMetadata>();
  const fieldIdsSet = new Set(fields.map((field) => field.id));

  columnMetadata.forEach((item) => {
    if (fieldIdsSet.has(item.fieldId)) {
      fieldIdsSet.delete(item.fieldId);
      fieldsMap.set(item.fieldId, item);
    }
  });

  fieldIdsSet.forEach((fieldId) => {
    const field = fieldMap[fieldId];
    if (field.type === FieldType.LOOKUP) {
      const referenceField = fieldMap[field.lookupOptions.referenceFieldId];
      fieldsMap.setBetween(referenceField.id, fieldId, { width: 0, fieldId, visibility: true });
    }
    fieldsMap.set(fieldId, { width: 0, fieldId, visibility: true });
  });

  const primaryFieldColumnMetadata = fieldsMap.get(primaryFieldId);
  const formattedColumnMetadata: IColumnMetadata[] = primaryFieldColumnMetadata
    ? [primaryFieldColumnMetadata]
    : [];

  fieldsMap.forEach((value) => {
    if (primaryFieldId === value.fieldId) {
      return;
    }
    formattedColumnMetadata.push(value);
  });

  return formattedColumnMetadata;
};
