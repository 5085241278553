import { IView, ViewType } from '@wf-mfe-maestro/api';
import { ReadonlyField } from 'state';

import { polishFSG } from './polishFSG';
import { formatTableViewMetaData } from './tableView/formatTableViewMetaData';

export const enhanceViewData = (
  view: IView,
  fieldMap: Record<string, ReadonlyField>,
  fields: ReadonlyField[]
): IView => {
  const correctFSG = polishFSG(view, fieldMap);

  if (view.type === ViewType.TABLE || view.type === ViewType.LIST) {
    const correctMetaData = formatTableViewMetaData(fields, view.metadata, fieldMap);
    return { ...view, metadata: correctMetaData, ...correctFSG };
  }

  return { ...view, ...correctFSG };
};
